$ln-webpack: true;
.ln-o-page--sticky-header{
    padding-top: 0px !important;
}

.topBanerTextLinks {
    text-decoration: underline !important;
    color: black !important;
    font-weight: normal !important;
    font-size: 16px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif !important;
}

.topBanerAccountName{
    text-decoration: none !important;
    color: black !important;
    font-weight: normal !important;
    font-size: 16px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif !important;
}

.topHeaderPadding{
    padding-top: 15px;
}

.emptyMessagebox{
    text-align: center;
    width: 100%;
    padding-top: 15px;
}

.horizontalDivider {
    border-top: 0px solid #bbb;
    margin-bottom: 0px;
    margin-right: 25px;
}

.ln-c-header__action-item svg.ln-c-icon{
    display: none;
}

.topRightNavItems{
    line-height: 80px;
}

.btnLeftOnPopup{
    width: 50%;
    text-align: left;
    float: left;
    padding-right: 10px;
}

.btnRightOnPopup{
    width: 50%;
    float: right;
    text-align: right;
    padding-right: 15px;
}

.btnPadding5{
    padding: 5px !important;
}

// fix for auto complete z index to show above map
.ln-c-dropdown-list--overlay {
    z-index: 10000 !important;
}

.marker-sains-div-icon {
    background-image: url('../public/JSMapicon.svg');
}
  
.marker-argos-div-icon {
    background-image: url('../public/ArgosMapIcon.svg');
}
  
.marker-common {
    background-repeat: no-repeat;
    background-size: contain;
    width: 38px !important;
    height: 52px !important;
    height: 100%;
    vertical-align: middle;
    color: white;
    margin-left: 0px !important;
    margin-right: 0px !important;
}
  
.marker-text {
    top: 5px;
    position: relative;
    width: 100%;
    align-content: center;
    text-align: center;
}

.exemption-field {
    max-width: 500px;
}

.divisionsummary-division-col {
    font-weight: bold;
}

@media only screen and (min-width: 750px) {   
    .ln-c-header__item--nav{
        width: 70% !important;
        line-height: 80px !important;
    }
}

@media only screen and (max-width: 700px) {
    .topBanerAccountName{
        margin-right: 85px !important;
    }
}

@import '@jsluna/style/scss/main.scss';
