.storeTitle {
    font-size: 22px;
    line-height: 26px;
    font-weight: 800;
    font-family: MaryAnn, Trebuchet MS, Arial, Helvetica, sans-serif;
    float: left;
    padding-bottom: 10px;
}

.storeOrderingnumber {
    height: 32px;
    left: 0.8rem;
    line-height: 1.5;
    top: 1rem;
    width: 35px;    
    background-size: contain;
    color: #fff;   
    font-size: 1rem;
    font-weight: normal;
    position: absolute;
    text-align: center;
    box-sizing: border-box;
    top: 0;
}

.storeIconSainsburys {
    background: url('../../../public/JSMapicon.svg') no-repeat center center;
}

.storeIconArgos {
    background: url('../../../public/ArgosMapIcon.svg') no-repeat center center;
}

@media only screen and (max-width: 500px) {
    .storeOrderingnumber{
        left: 5px !important;
    }
}

.storeMile {
    vertical-align: middle;
    padding-left: 10px;;
}

.dvPledgeSuccessWarpper {
    top: 30%;
    height: 100%;
    position:absolute;
}

.bottompaddingSuccessmsg {
    padding-bottom: 12px;
}

.justifyBodyText {
    text-align: justify;
}

.paddingleft15 {
    padding-left: 15px;
}

.paddingleft20 {
    padding-left: 25px;
}

ul.paddingleft15 li {
    padding: 7px;
}

.supDates {
    vertical-align: super;
    font-size: small;
}

.btnPledgehere {
    padding: 8px !important;
}
.fontsize13 {
    font-size: 13px;
}

.ln-c-accordion__body {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.mapContainer {
    width: 100%;
    height: 700px;
    padding: 5px 5px 5px 5px;
}

.mapInfoContent {
    font-size: 14px;
    max-width: 300px;
    min-width: 200px;
    height: 100%;
    padding: 5px 5px 5px 5px;
}

.mapMarkerLabel {
    position: relative;
    display: table;
    border-spacing: 0px;
    top: 4px;
    left: 3px;
}

@media only screen and (max-width: 500px) {
    .storeOrderingnumber{
        left: 5px !important;
    }
}

.textUnderscore {
    text-decoration: underline !important;
}